body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  /* Set the background color to black */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  z-index: 1;
  background-color: rgba(245, 242, 242, 0.8);
  padding: 4px 4px 0px 4px;
  border-radius: 50%;
}

.custom-arrow.left {
  left: 10px;
}

.custom-arrow.right {
  right: 10px;
}

@font-face {
  font-family: 'Archivo Expanded';
  src: url('./assets/fonts/Archivo_Expanded-Medium.ttf');
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #51b3a7;
  border: 4px solid #202928;
  border-radius: 5px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #51b3a7;
}

.gradientText {
  font-family: 'Archivo expanded';
  background: linear-gradient(102deg, #00a99d 36.48%, #b0d456 124.88%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backdrop-filter: blur(30px);
}

.gradientText1 {
  font-family: 'Archivo expanded';
  background: linear-gradient(37deg, #00a99d 3.89%, #b0d456 99.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backdrop-filter: blur(30px);
}

.gradientTextWhite {
  background: linear-gradient(16deg, #fff 42.36%, rgba(255, 255, 255, 0) 108.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.congratsWrapper {
  position: relative;
  height: auto;
  padding: 0 0rem 9rem;
  margin-top: 0rem;
  border-radius: 0.2rem;
  overflow: hidden;
  max-width: 600px;
  z-index: 1;
}
.congratsWrapper .congratsWrapper-alone {
  width: '100%';
  font-size: 40px;
  font-family: 'Archivo expanded';
  background: linear-gradient(102deg, #00a99d 36.48%, #b0d456 124.88%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  backdrop-filter: blur(30px);
  z-index: 9;
  position: absolute;
  top: 0%;
}
.congratsWrapper .congratsWrapper-words {
  position: absolute;
  left: 0;
  z-index: 5;
  width: 100%;
}

.congratsWrapper .congratsWrapper-words .change {
  display: inline-block;
  position: absolute;
  left: 140px;
  opacity: 0;
  animation: changeword 6s linear infinite;
  text-align: left;
}
.congratsWrapper .congratsWrapper-words .change:nth-child(1) {
  animation-delay: 0s;
}
.congratsWrapper .congratsWrapper-words .change:nth-child(2) {
  animation-delay: 2s;
}
.congratsWrapper .congratsWrapper-words .change:nth-child(3) {
  animation-delay: 4s;
}

.congratsWrapper .congratsWrapper-words .change:nth-child(4) {
  animation-delay: 6s;
}
@keyframes changeword {
   0% {
       transform: translateY(50px);
       opacity: 0;
  }
   5% {
       opacity: 1;
       transform: translateY(0);
  }
   20% {
       opacity: 1;
       transform: translateY(0);
  }
   30% {
       opacity: 0;
       transform: translateY(-50px);
  }
   80% {
       opacity: 0;
       transform: translateY(50px);
  }
}

/*Footer*/
h3 {
  color: #fff;
  font-size: 30px;
  margin-top: 20px;
  text-align: center;
}

@-moz-document url-prefix() {
  .animated-container div {
    background: linear-gradient(102deg, #00a99d 36.48%, #b0d456 124.88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
    -moz-appearance: textfield;
}

.customInput {
  height: 48px;
  width: 16%;
  margin-right: 8px;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #969696;
  color: #51b3a7;
  outline: none;
  font-family: 'IBM Plex Sans', sans-serif;
}

.customInput:focus {
  border: 2px solid #51b3a7;
}

.MuiDateCalendar-root .MuiPickersFadeTransitionGroup-root .Mui-selected {
  background: #e5f6f5 !important;
  color: #00a99d;
}

.css-du1tib-MuiPickersYear-yearButton {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
}

.css-du1tib-MuiPickersYear-yearButton .Mui-selected {
  text-align: center;
}

.MuiPickersYear-yearButton {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
}

.css-13fv9kz-MuiPickersMonth-monthButton {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.custom-datepicker-dark label{
  color: #FFF
}

.custom-datepicker-dark .MuiOutlinedInput-root {
  color: #FFF;
}

.custom-datepicker-dark fieldset {
  border-color: #FFF;
}

.custom-datepicker-dark svg {
  color: #FFF
}

.darkMode .MuiPickersDay-today:not(.Mui-selected) span {
  width: 4px;
  height: 4px;
  background: #00A99D;
  top: auto;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50%;
}

.darkMode .MuiPickersDay-today:not(.Mui-selected) {
  border: none;
}

.gradient-border .MuiDateCalendar-root .MuiPickersFadeTransitionGroup-root .Mui-selected {
  background-color: #00221F !important;
  color: #00a99d !important;
}

.darkMode .MuiPickersDay-root{
  color: #FFF !important
}

.darkMode {
  background: #00655E;
}

.darkMode .MuiPickersArrowSwitcher-root {
  display: none;
}

.darkMode .MuiPickersCalendarHeader-root {
  justify-content: center;
}

.darkMode .MuiPickersCalendarHeader-labelContainer {
  margin-right: 0;
}

.darkMode .MuiPickersCalendarHeader-switchViewButton {
  display: none;
}

.MuiPickersMonth-monthButton {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sanction-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sanction-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.sanction-scrollbar::-webkit-scrollbar-thumb {
  background: #51b3a7;
  border: 4px solid #FFF;
  border-radius: 5px;
}

/* Handle on hover */
.sanction-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #51b3a7;
}

.schedule-scrollbar::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.schedule-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.schedule-scrollbar::-webkit-scrollbar-thumb {
  background: #FFF;
  border: 6px solid #002723;
  border-radius: 10px;
}

/* Handle on hover */
.schedule-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #FFF;
}

.gradient-border {
  background: linear-gradient(121deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%);
  padding: 1px;
  border-radius: 10px;
}

.gradient-border .MuiDateCalendar-root{
  background-color: #002723;
  background-image: linear-gradient(108deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 83.15%);
  border-radius: 10px;
}

.darkMode .MuiPickersCalendarHeader-label {
  color: #FFF;
}

.darkMode .MuiPickersYear-root {
  color: #FFF;
}

.darkMode .MuiMonthCalendar-root {
  background: transparent;
  color: #FFF;
}

.darkMode .MuiDayCalendar-weekDayLabel {
  color: #00A99D;
  font-weight: 500;
  font-family: 'IBM Plex Sans';
}

.darkMode .MuiPickersDay-root.Mui-disabled {
  opacity: 0.45;
}

.darkMode .MuiDayCalendar-header {
  justify-content: space-evenly;
}

.darkMode .MuiDayCalendar-weekContainer {
  justify-content: space-evenly;
}

.darkMode .MuiMonthCalendar-root {
  width: 100%;
}

.darkMode .MuiYearCalendar-root {
  width: 100%;
}

.textFieldDark.MuiTextField-root, .textFieldDark.MuiFormControl-root {
  background: linear-gradient(163deg, rgba(255, 255, 255, 0.44) 0%, rgba(255, 255, 255, 0.00) 73.14%);
  border-radius: 10px;
  padding: 1px
}

.textFieldDark.MuiTextField-root.disabled, .textFieldDark.MuiFormControl-root.disabled {
  opacity: 0.5;
}

.textFieldDark.MuiTextField-root input, .textFieldDark.MuiFormControl-root input {
  color: white;
  -webkit-text-fill-color: white
}

.textFieldDark.MuiTextField-root label, .textFieldDark.MuiFormControl-root label{
  border-radius: 4px;
  color: #51B3A7;
}

.textFieldDark.MuiTextField-root .Mui-focused label, .textFieldDark.MuiFormControl-root .Mui-focused label{
  /* background: #002723; */
  padding: 0 6px;
  margin: 0 -2.5px;
}

.textFieldDark.MuiTextField-root .MuiInputLabel-shrink, .textFieldDark.MuiFormControl-root .MuiInputLabel-shrink {
  background: #002723;
  padding: 0 6px;
  margin: 0 -2.5px;
}

.textFieldDark.MuiTextField-root .MuiInputAdornment-root p {
  color: white
}

.textFieldDark.MuiTextField-root .MuiOutlinedInput-root, .textFieldDark.MuiFormControl-root .MuiOutlinedInput-root {
  color: #FFF;
  background-color: #002723;
  background-image: linear-gradient(114deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 113.45%);
  border-radius: 10px;
  box-shadow: 0px 4px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(207px);
}

.textFieldDark.MuiFormControl-root .MuiSvgIcon-root {
  color: white;
}

/* .textFieldDark.MuiTextField-root input{
  color: #FFF;
  background: linear-gradient(114deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 113.45%);
  border-radius: 10px;
  backdrop-filter: blur(207px);
} */

.heightAnimation {
  transition: all 0.3s ease-in-out;
}

.autofill input:-webkit-autofill, .autofill input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #002723 inset;
  -webkit-text-fill-color: #FFF;
  background-image: linear-gradient(114deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 250, 250, 0.00) 113.45%);
}
